import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('header', (props: any) => ({
    showAnnouncementBar: true,
    isShopOpen: false,
    isAboutOpen: false,
    showHeader: true,

    init() {
      if (props.hideHeader) {
        document.addEventListener("wheel", (e) => {
          if (e.deltaY > 0) {
            this.showHeader = false;
          } else if (e.deltaY < 0) {
            this.showHeader = true;
          }
        });
      }
    },
    openShop() {
      this.isAboutOpen = false;
      this.isShopOpen = true;
    },
    openAbout() {
      this.isShopOpen = false;
      this.isAboutOpen = true;
    },
    closeMegamenu() {
      this.isShopOpen = false;
      this.isAboutOpen = false;
    },
    closeAnnouncementBar() {
      const headerPlaceholder = document.querySelector<HTMLElement>('.header-placeholder');

      if (headerPlaceholder) {
        headerPlaceholder.style.height = `${headerPlaceholder.offsetHeight - 45}px`;
      }

      this.showAnnouncementBar = false;
    }
  }));
};
