import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.store('ui', {
    cartDrawerOpen: false as boolean,

    async init() {
      if (new URLSearchParams(location.search).get('action') === 'open-cart-drawer') {
        await new Promise(r => setTimeout(r, 1000));
        this.toggleCartDrawer();
      }
    },

    toggleCartDrawer(state = null) {
      if (state !== null) {
        this.cartDrawerOpen = state;
      } else {
        this.cartDrawerOpen = !this.cartDrawerOpen;
      }

      document.body.style.overflowY = this.cartDrawerOpen ? 'hidden' : '';

      if (window?.Rebuy) {
        this.cartDrawerOpen ? window.Rebuy.SmartCart.show() : window.Rebuy.SmartCart.hide();
      }

      const gorgiasChat = document.getElementById('gorgias-chat-container');

      if (!gorgiasChat) {
        return;
      }

      gorgiasChat.style.display = this.cartDrawerOpen ? 'none' : 'block';
    },
  } as any);
};
