export const updatePage = async (url: string) => {
  const response = await fetch(url).then((response) => response.text());

  const html = document.createElement('div');
  html.innerHTML = response;

  const newContent = html.querySelectorAll('[updatable]');

  history.replaceState({}, "", url);

  document.body.querySelectorAll('[updatable]').forEach((item, index) => {
    item.innerHTML = newContent[index].innerHTML;
  })
};
